import { Button } from 'components/ui/atomic-components';
import styled from 'styled-components';
import { FontM, FontS } from 'styles/typography';

export const GridStyles = {
  GridWrapper: styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[12]};
    max-width: 100%;
    flex-direction: column;
  `,

  Box: styled.div`
    width: 206px;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing[8]};
    overflow: hidden;
    padding: ${({ theme }) => theme.spacing[12]};
    border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    border-radius: ${({ theme }) => theme.borderRadius.m};
    cursor: pointer;
  `,

  ImageContainer: styled.img`
    height: 32px;
    width: 32px;
    object-fit: contain;
  `,
  Name: styled.div`
    ${FontS};
  `,
  GridHeader: styled.div`
    ${FontM};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,
  ItemsWrapper: styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[8]};
    flex-flow: row wrap;
  `,
  PremiumIconWrapper: styled.div`
    background-color: ${({ theme }) => theme.colors.green200};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.borderRadius.xl};
    height: 16px;
    width: 16px;
  `,
  LeftSection: styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing[8]};
    align-items: center;
  `,
  StyledButton: styled(Button)`
    width: 100px;

    .btn-content {
      align-items: end;
      display: flex;
      gap: ${({ theme }) => theme.spacing[4]};
    }
  `,
};
