import { ReactComponent as PopoutLinkIcon } from 'assets/ic_popout_link.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAssetUrl } from 'utils';
import { CommonContent } from '../common';
import { FormConnectorStyles, PopupModalStyles } from '../styles';
import { type ModalContentProps } from '../types';
import { LookerForm } from './form';

const { StyledP } = PopupModalStyles;
const {
  LeftPanel,
  Title,
  ImageContainer,
  FullWidthWrapper,
  RightPanel,
  RightTitle,
  RightDescription,
} = FormConnectorStyles;

export const LookerContent: FC<React.PropsWithChildren<ModalContentProps>> = ({
  item,
  onConnectionSuccess,
}) => {
  const br = <br />;
  const docLink =
    'https://docs.drivetrain.ai/integration-guide/integrate-with-drivetrain/bi/integrating-with-looker';

  return (
    <FullWidthWrapper>
      <LeftPanel>
        <ImageContainer
          alt=""
          src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)}
        />
        <Title>{item.displayName}</Title>
        <StyledP>
          <FormattedMessage id="integrations.looker.modal.desc" />
        </StyledP>
        <StyledP>
          <strong>
            <FormattedMessage id="integrations.looker.modal.para" />
          </strong>
        </StyledP>
        <StyledP>
          <FormattedMessage id="integrations.looker.modal.line1" />
        </StyledP>
        <StyledP>
          <FormattedMessage id="integrations.looker.modal.list" values={{ br }} />
        </StyledP>
        <StyledP>
          <FormattedMessage id="integrations.looker.modal.line2" />
        </StyledP>
        <StyledP>
          <strong>
            <FormattedMessage id="integrations.modal.add_resources" />
          </strong>
        </StyledP>
        <Button
          icon={<IconShell color="blue60" icon={PopoutLinkIcon} />}
          onClick={() => window.open(docLink, '_blank', 'noopener')}
        >
          <FormattedMessage id="integrations.modal.linktext" />
        </Button>
        <CommonContent {...item} />
      </LeftPanel>
      <RightPanel>
        <RightTitle>
          <FormattedMessage
            id="integrations.sfdc.modal.title"
            values={{ name: item.displayName }}
          />
        </RightTitle>
        <RightDescription>
          <FormattedMessage
            id="integrations.sfdc.modal.description"
            values={{ name: item.displayName }}
          />
        </RightDescription>
        <LookerForm service={item.id} onConnectionSuccess={onConnectionSuccess} />
      </RightPanel>
    </FullWidthWrapper>
  );
};
