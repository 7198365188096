import { IconShell, Modal } from 'components/ui/atomic-components';
import styled from 'styled-components';

export const FinishModalStyles = {
  StyledShell: styled(IconShell)`
    width: 72px;
    height: auto;
  `,

  StyledModal: styled(Modal)`
    height: 268px;
  `,

  ContentWrapper: styled.div`
    display: flex;
    flex-flow: column;
    gap: ${({ theme }) => theme.spacing[16]};
    align-items: center;
    padding: ${({ theme }) => theme.spacing[24]};
    text-align: center;
  `,

  HeavyText: styled.span`
    font-size: ${({ theme }) => theme.fontSize.paragraph};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  `,

  Subtext: styled.span`
    font-size: ${({ theme }) => theme.fontSize.caption};
    color: ${({ theme }) => theme.colors.textTertiary};
  `,
};
