import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { PopupModalStyles } from './styles';
import { type ModalContentProps } from './types';

const { FullWidthWrapper, Content, StyledP } = PopupModalStyles;

export const PremiumContent: FC<React.PropsWithChildren<ModalContentProps>> = ({ item }) => {
  const bold = (text: string) => <b>{text}</b>;

  return (
    <FullWidthWrapper>
      <Content>
        <StyledP>
          <FormattedMessage
            id="integrations.premium.content"
            values={{ instance: item.displayName, b: bold }}
          />
        </StyledP>
      </Content>
    </FullWidthWrapper>
  );
};
