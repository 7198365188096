import { ReactComponent as GoogleIcon } from 'assets/ic_Google.svg';
import { ReactComponent as PopoutLinkIcon } from 'assets/ic_popout_link.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/v2/arrow-right.svg';
import { gsheetLogin } from 'components/modules/settings/fivetran/common/connectors-auth';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { getAssetUrl } from 'utils';
import { CommonContent } from '../common';
import { useGoogleFlow } from '../hooks/use-google-flow';
import { FormConnectorStyles, PopupModalStyles } from '../styles';
import { type ModalContentProps } from '../types';

const { StyledLI, StyledP, StyledButton, StyledOL } = PopupModalStyles;
const {
  LeftPanel,
  Title,
  ImageContainer,
  FullWidthWrapper,
  RightPanel,
  RightTitle,
  RightDescription,
} = FormConnectorStyles;

const StyledIcon = styled(IconShell)`
  padding: ${({ theme }) => theme.spacing[4]};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  background: ${({ theme }) => theme.colors.white};
  position: absolute;
  left: 4px;
  top: 6px;
  margin-left: ${({ theme }) => theme.spacing[2]};
`;

export const GSheetsContent: FC<React.PropsWithChildren<ModalContentProps>> = ({ item }) => {
  const bold = (text: string) => <b>{text}</b>;
  const docLink =
    'https://docs.drivetrain.ai/integration-guide/integrate-with-drivetrain/spreadsheets/integrating-with-google-sheets';

  const onClick = () => {
    gsheetLogin({ item });
  };

  const { isLoading } = useGoogleFlow(item);

  return (
    <FullWidthWrapper>
      <LeftPanel>
        <ImageContainer
          alt=""
          src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)}
        />
        <Title>{item.displayName}</Title>
        <StyledP>
          <strong>
            <FormattedMessage
              id="integrations.modal.common.para"
              values={{ service: item.displayName }}
            />
          </strong>
        </StyledP>
        <StyledOL>
          <StyledLI>
            <FormattedMessage id="integrations.gsheets.connect_modal.line1" values={{ b: bold }} />
          </StyledLI>
          <StyledLI>
            <FormattedMessage id="integrations.gsheets.connect_modal.line2" />
          </StyledLI>
          <StyledLI>
            <FormattedMessage id="integrations.gsheets.connect_modal.line3" />
          </StyledLI>
        </StyledOL>
        <StyledP>
          <strong>
            <FormattedMessage id="integrations.modal.add_resources" />
          </strong>
        </StyledP>
        <Button
          icon={<IconShell color="blue60" icon={PopoutLinkIcon} />}
          onClick={() => window.open(docLink, '_blank', 'noopener')}
        >
          <FormattedMessage id="integrations.modal.linktext" />
        </Button>
        <CommonContent {...item} />
      </LeftPanel>
      <RightPanel>
        <RightTitle>
          <FormattedMessage
            id="integrations.sfdc.modal.title"
            values={{ name: item.displayName }}
          />
        </RightTitle>
        <RightDescription>
          <FormattedMessage
            id="integrations.sfdc.modal.description"
            values={{ name: item.displayName }}
          />
        </RightDescription>

        <StyledButton
          $width="260px"
          icon={<StyledIcon icon={GoogleIcon} size="lg" />}
          loading={isLoading}
          type={'primary'}
          onClick={onClick}
        >
          <FormattedMessage id="integrations.gsheets.modal.button_text" />
          <IconShell color="gray00" icon={ArrowRightIcon} />
        </StyledButton>
      </RightPanel>
    </FullWidthWrapper>
  );
};
