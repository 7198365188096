import { ReactComponent as NumberIcon } from 'assets/v2/123.svg';
import { ReactComponent as StringIcon } from 'assets/v2/abc.svg';
import { ReactComponent as CalendarIcon } from 'assets/v2/calendar-due.svg';
import { IconText } from 'components/ui/atomic-components';
import { type IconType } from 'components/ui/atomic-components/icon-shell';
import { QueryTableColumnDataType } from 'data/big-query';
import styled from 'styled-components';
import { SingleLineEllipsis } from 'styles/typography';

const IconOptions = styled.div`
  display: none;
  position: relative;
  top: 1px;
  gap: ${({ theme }) => theme.spacing[4]};

  :has(.ant-dropdown-open, .ant-popover-open, .column-filter-applied) {
    display: flex;
  }
`;

const TitleText = styled(IconText)`
  max-width: 100%;
  height: 100%;
  position: relative;
  top: 1px;

  svg {
    min-width: 16px;
    flex-shrink: 0;
  }
`;

const IconTextWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[4]};
  overflow: hidden;

  > svg {
    margin-top: 4px;
  }
`;

export default {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};
    position: relative;
    overflow: hidden;

    :hover {
      ${IconOptions} {
        display: flex;
      }

      ${IconTextWrapper} {
        max-width: calc(100% - 56px);
      }
    }
  `,

  TitleText,

  IconTextWrapper,

  ColTitle: styled.span`
    ${SingleLineEllipsis}
  `,

  IconOptions,

  IconMapper: {
    [QueryTableColumnDataType.String]: StringIcon,
    [QueryTableColumnDataType.Date]: CalendarIcon,
    [QueryTableColumnDataType.Numeric]: NumberIcon,
  } as Record<QueryTableColumnDataType, IconType>,

  MaskedColumnIcon: styled.div`
    display: flex;
    align-items: center;
    margin-left: ${({ theme }) => theme.spacing[4]};
  `,

  RightIconSection: styled.div`
    display: flex;
  `,
};
