import styled from 'styled-components';
import { FontL } from 'styles/typography';

export const styles = {
  LeftNavSection: styled.div`
    width: 300px;
    padding: ${({ theme }) => theme.spacing[24]};
    gap: ${({ theme }) => theme.spacing[8]};
    display: flex;
    flex-direction: column;
  `,
  RightNavSection: styled.div`
    width: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing[24]};
    padding-right: 160px;
    gap: ${({ theme }) => theme.spacing[16]};
    height: 100%;
  `,
  AllConnectorsWrapper: styled.div`
    display: flex;
    flex-direction: row;
    height: calc(100% - 60px);
    width: 100%;
  `,
  PopoverTitle: styled.div`
    ${FontL};

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[24]}`};
    border-bottom: 1px solid ${({ theme }) => theme.colors.outlineDefault};
  `,
  PopoverWrapper: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  `,
  TitleWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};
  `,
  ComponentWrapper: styled.div`
    height: calc(100% - 60px);
    width: 100%;
  `,
};
