import { useMutation } from '@tanstack/react-query';
import { ReactComponent as LinkIcon } from 'assets/v2/link-data.svg';
import { IconShell, notification, type OptionBase } from 'components/ui/atomic-components';
import {
  type IntegrationConnectionItem,
  type IntegrationModalContent,
  IntegrationProvider,
} from 'data/integrations/fivetran';
import { useGetConnectedIntegrations } from 'data/integrations/fivetran/hooks';
import { MergeDevApi } from 'data/integrations/merge.dev';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { GridSection } from './grid';

const MergeMode = 'MERGE';

const ScrollableList = styled.div`
  overflow-y: auto;
  height: 100%;
  gap: ${({ theme }) => theme.spacing[32]};
  display: flex;
  flex-direction: column;
`;

const integrationsWithDedicatedModals = [
  IntegrationProvider.Salesforce,
  IntegrationProvider.GoogleSheets,
  IntegrationProvider.Netsuite,
  IntegrationProvider.Looker,
  IntegrationProvider.Hubspot,
  IntegrationProvider.Xero,
  IntegrationProvider.ZohoBooks,
  IntegrationProvider.SalesforceSandbox,
  IntegrationProvider.Pipedrive,
  IntegrationProvider.Snowflake,
  IntegrationProvider.Airtable,
  IntegrationProvider.Twilio,
  IntegrationProvider.Rippling,
];

export const ConnectorsList = ({
  mergeIntegrationIdRef,
  setMergeToken,
  setActiveItem,
  options,
  categorisedItems,
  searchText,
  setShowPopupModal,
}: {
  mergeIntegrationIdRef: React.MutableRefObject<string>;
  setActiveItem: React.Dispatch<React.SetStateAction<IntegrationModalContent | null>>;
  setMergeToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  options: OptionBase[];
  categorisedItems: { category: string; items: IntegrationModalContent[] }[];
  searchText: string;
  setShowPopupModal: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
  const { quickbooksSyncMode } = useFlags();
  const intl = useIntl();
  const { data: connectedItems } = useGetConnectedIntegrations();

  const { mutate: connectMerge } = useMutation(
    (id: number) => MergeDevApi.getConnectorLinkToken(id),
    {
      onSuccess: ({ token, mergeIntegrationId }) => {
        mergeIntegrationIdRef.current = mergeIntegrationId;
        setMergeToken(token);
      },
      onError: defaultApiErrorHandler,
    },
  );

  const checkIfReachedMaxConnectionLimit = (item: IntegrationConnectionItem): boolean => {
    // These connections are restricted to have just one instance
    return (
      [
        IntegrationProvider.Salesforce,
        IntegrationProvider.Looker,
        IntegrationProvider.Hubspot,
        IntegrationProvider.Pipedrive,
      ].includes(item.name) &&
      !!connectedItems?.find((connectedItem) => item.name === connectedItem.name)
    );
  };

  const onItemActivate = (item: IntegrationConnectionItem) => {
    if (checkIfReachedMaxConnectionLimit(item)) {
      notification.info({
        message: intl.formatMessage({
          id: 'integrations.connections.only_one_instance_message',
        }),
        icon: <IconShell color="green500" icon={LinkIcon} size="sm" />,
      });

      return;
    }

    setActiveItem(item);

    if (item.name === IntegrationProvider.Quickbooks) {
      if (quickbooksSyncMode === MergeMode) {
        connectMerge(item.id);
      } else {
        setShowPopupModal(true);
      }
    } else if (item && !item.isPremium && !integrationsWithDedicatedModals.includes(item.name)) {
      connectMerge(item.id);
    } else {
      setShowPopupModal(true);
    }
  };

  return (
    <ScrollableList id={'connectors-list'}>
      {categorisedItems.map((cItem) => (
        <GridSection
          key={cItem.category}
          header={options.find((o) => o.value === cItem.category)?.label as string}
          items={cItem.items}
          searchTerm={searchText}
          onActivateItem={onItemActivate}
        />
      ))}
    </ScrollableList>
  );
};
