import { ReactComponent as PopoutLinkIcon } from 'assets/ic_popout_link.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/v2/arrow-right.svg';
import { getCallbackUrl, getState } from 'components/modules/settings/fivetran/common/util';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAssetUrl } from 'utils';
import { CommonContent } from '../common';
import { useCommonLoginFlow } from '../hooks/use-common-flow';
import { FormConnectorStyles, PopupModalStyles } from '../styles';
import { type ModalContentProps } from '../types';

const { StyledP, StyledButton } = PopupModalStyles;
const {
  LeftPanel,
  Title,
  ImageContainer,
  FullWidthWrapper,
  RightPanel,
  RightTitle,
  RightDescription,
} = FormConnectorStyles;

export const HubspotContent: FC<React.PropsWithChildren<ModalContentProps>> = ({ item }) => {
  const { clientId } = item;

  const docLink =
    'https://docs.drivetrain.ai/integration-guide/integrate-with-drivetrain/crm/integrating-with-hubspot';

  const { isLoading } = useCommonLoginFlow(item);
  const state = getState(item.id);

  const onClick = () => {
    window.open(
      `https://app.hubspot.com/oauth/authorize?client_id=${clientId}&scope=crm.schemas.deals.read%20business-intelligence%20oauth%20crm.objects.companies.read%20crm.objects.deals.read%20crm.schemas.contacts.read%20crm.objects.contacts.read%20crm.schemas.companies.read&optional_scope=crm.schemas.quotes.read%20crm.objects.line_items.read%20content%20crm.schemas.line_items.read%20crm.objects.owners.read%20settings.users.teams.read%20crm.objects.marketing_events.read%20crm.schemas.custom.read%20crm.objects.custom.read%20crm.objects.feedback_submissions.read%20sales-email-read%20crm.objects.goals.read%20crm.lists.read%20settings.users.read%20crm.objects.quotes.read%20e-commerce&redirect_uri=${getCallbackUrl()}&state=${state}`,
      '_self',
    );
  };

  return (
    <FullWidthWrapper>
      <LeftPanel>
        <ImageContainer
          alt=""
          src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)}
        />
        <Title>{item.displayName}</Title>
        <StyledP>
          <FormattedMessage id="integrations.hubspot.modal.desc" />
        </StyledP>
        <StyledP>
          <strong>
            <FormattedMessage id="integrations.modal.add_resources" />
          </strong>
        </StyledP>
        <Button
          icon={<IconShell color="blue60" icon={PopoutLinkIcon} />}
          onClick={() => window.open(docLink, '_blank', 'noopener')}
        >
          <FormattedMessage id="integrations.modal.linktext" />
        </Button>
        <CommonContent {...item} />
      </LeftPanel>
      <RightPanel>
        <RightTitle>
          <FormattedMessage
            id="integrations.sfdc.modal.title"
            values={{ name: item.displayName }}
          />
        </RightTitle>
        <RightDescription>
          <FormattedMessage
            id="integrations.sfdc.modal.description"
            values={{ name: item.displayName }}
          />
        </RightDescription>{' '}
        <StyledButton loading={isLoading} type="primary" onClick={onClick}>
          <FormattedMessage id="integrations.sfdc.modal.button_text" />
          <IconShell color="gray00" icon={ArrowRightIcon} />
        </StyledButton>
      </RightPanel>
    </FullWidthWrapper>
  );
};
