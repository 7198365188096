import { metricTypesListV2 } from 'components/modules/metrics/constants';
import { useListMutations } from 'components/modules/modelling/lists/hooks';
import { NumberStepper, type OptionBase, Segmented, Select } from 'components/ui/atomic-components';
import {
  blankValueOptions,
  displayUnitOptions,
  negativeValueOptions,
  zeroFormatOptions,
} from 'components/ui/atomic-components/data-format-modal-v2/constants';
import { Sample } from 'components/ui/atomic-components/data-format-modal-v2/sample-section';
import { styles } from 'components/ui/atomic-components/data-format-modal-v2/styles';
import { type CustomTypeFormat } from 'components/ui/atomic-components/data-format-modal-v2/types';
import { ValueFormatUnit } from 'components/ui/atomic-components/data-format-modal-v2/value-format';
import { CurrencySelector } from 'components/ui/currency-selector';
import { useCurrency } from 'data/currencies/hooks/use-currency';
import { type DataFormatWithType } from 'data/metrics';
import { useEffect, useState, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import {
  BlankValueType,
  DataFormattingType,
  DisplayUnitType,
  NegativeValueType,
  ZeroFormatType,
} from 'utils/data-formatter/types';

const { Wrapper, Unit, UnitsSection, StyledModal, UnitLabel } = styles;

interface Props {
  open: boolean;
  colName: string;
  onClose: () => void;
}

export const ColumnNumberFormat = ({ open, colName, onClose }: Props): ReactElement => {
  const tenantCurrency = useCurrency();
  const intl = useIntl();

  const { updateListMutation } = useListMutations();

  const listId = useListStoreContext((s) => s.id);
  const dataFormatMap = useListStoreContext((s) => s.config.dataFormatMap);
  const config = useListStoreContext((s) => s.config);
  const setConfig = useListStoreContext((s) => s.setConfig);

  const [dataFormat, setDataFormat] = useState(dataFormatMap?.[colName] as DataFormatWithType);

  const handleTypeChange = (type: DataFormattingType) => {
    if (type === DataFormattingType.Percentage) {
      setDataFormat((prev) => ({
        ...prev,
        type,

        displayUnit: DisplayUnitType.Full,
        digitsAfterDecimal: 2,
      }));
    } else {
      setDataFormat((prev) => ({
        ...prev,
        type,
      }));
    }
  };

  const onSubmit = async () => {
    const updatedConfig = {
      ...config,
      dataFormatMap: {
        ...dataFormatMap,
        [colName]: dataFormat,
      },
    };

    setConfig(updatedConfig);

    updateListMutation
      .mutateAsync({
        id: listId,
        list: { config: updatedConfig },
        skipToast: true,
      })
      .catch(() => {
        setConfig(config);
      });

    onClose();
  };

  useEffect(() => {
    setDataFormat(dataFormatMap?.[colName] as DataFormatWithType);
  }, [colName, dataFormatMap]);

  return (
    <StyledModal
      cancelText={<FormattedMessage id="cancel" />}
      destroyOnClose
      okText={<FormattedMessage id="data_format.modal.apply_format" />}
      open={open}
      title={<FormattedMessage id="data_format.modal.menu_title" />}
      width={487}
      onCancel={onClose}
      onOk={onSubmit}
    >
      <Wrapper>
        <Sample
          currency={dataFormat?.currency || tenantCurrency}
          digitsAfterDecimal={dataFormat?.digitsAfterDecimal ?? 0}
          displayUnit={dataFormat?.displayUnit || DisplayUnitType.Full}
          type={dataFormat?.type || DataFormattingType.Number}
        />

        <UnitsSection>
          <Unit>
            <UnitLabel>
              <FormattedMessage id="data_format.modal.data_type" />
            </UnitLabel>

            <Segmented
              options={metricTypesListV2.map((o) => ({ value: o.value, icon: o.icon }))}
              type="icon"
              value={dataFormat?.type || DataFormattingType.Number}
              onChange={(value) => handleTypeChange(value as DataFormattingType)}
            />
          </Unit>

          {dataFormat?.type === DataFormattingType.Currency && (
            <Unit>
              <UnitLabel>
                <FormattedMessage id="currency" />
              </UnitLabel>

              <CurrencySelector
                enableAllCurrenciesOptions
                value={dataFormat?.currency || tenantCurrency}
                onChange={(val) =>
                  setDataFormat((prev) => ({
                    ...prev,
                    currency: val,
                  }))
                }
              />
            </Unit>
          )}

          <Unit>
            <UnitLabel>
              <FormattedMessage id="data_format.modal.display_type" />
            </UnitLabel>

            <Select
              options={
                (dataFormat?.type !== DataFormattingType.Percentage
                  ? displayUnitOptions.filter((option) =>
                      option.currencies.includes(dataFormat?.currency || tenantCurrency),
                    )
                  : [
                      displayUnitOptions.find((option) => option.value === DisplayUnitType.Full),
                    ]) as OptionBase[]
              }
              styles={{
                menu: (base) => {
                  return {
                    ...base,
                    minWidth: 150,
                  };
                },
              }}
              value={displayUnitOptions.find(
                (o) => o.value === (dataFormat?.displayUnit || DisplayUnitType.Full),
              )}
              onChange={(updatedValue) =>
                setDataFormat((prev) => ({
                  ...prev,
                  displayUnit: updatedValue?.value,
                }))
              }
            />
          </Unit>

          <Unit>
            <UnitLabel>
              <FormattedMessage id="data_format.modal.digits_after_decimal" />
            </UnitLabel>

            <NumberStepper
              max={4}
              min={0}
              value={dataFormat?.digitsAfterDecimal ?? 0}
              onDecrease={(val) =>
                setDataFormat((prev) => ({
                  ...prev,
                  digitsAfterDecimal: val,
                }))
              }
              onIncrease={(val) =>
                setDataFormat((prev) => ({
                  ...prev,
                  digitsAfterDecimal: val,
                }))
              }
            />
          </Unit>

          <ValueFormatUnit<CustomTypeFormat<BlankValueType>>
            currentValue={dataFormat?.blankValueFormat || BlankValueType.Blank}
            options={blankValueOptions}
            title={intl.formatMessage({ id: 'data_format.modal.blank_values' })}
            onChange={(updatedValue) =>
              setDataFormat((prev) => ({
                ...prev,
                blankValueFormat: updatedValue as BlankValueType,
              }))
            }
          />

          <ValueFormatUnit<CustomTypeFormat<ZeroFormatType>>
            currentValue={dataFormat?.zeroFormat ?? ZeroFormatType.Number}
            options={zeroFormatOptions}
            title={intl.formatMessage({ id: 'data_format.modal.zero_values' })}
            onChange={(updatedValue) =>
              setDataFormat((prev) => ({
                ...prev,
                zeroFormat: updatedValue as ZeroFormatType,
              }))
            }
          />

          <ValueFormatUnit<CustomTypeFormat<NegativeValueType>>
            currentValue={dataFormat?.negativeValueFormat || NegativeValueType.Brackets}
            options={negativeValueOptions}
            title={intl.formatMessage({ id: 'data_format.modal.negative_values' })}
            onChange={(updatedValue) =>
              setDataFormat((prev) => ({
                ...prev,
                negativeValueFormat: updatedValue as NegativeValueType,
              }))
            }
          />
        </UnitsSection>
      </Wrapper>
    </StyledModal>
  );
};
