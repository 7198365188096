import { Space, Tag } from 'components/ui/atomic-components';
import { type IntegrationProvider } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Caption, H5 } from 'styles/typography';
import { getAssetUrl } from 'utils';

const StyledTag = styled(Tag)`
  height: ${({ theme }) => `${theme.height.xs}px`};
  padding: ${({ theme }) => `0 ${theme.spacing[4]}`};
`;

const HeaderWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[4]};
  height: 44px;
  align-items: center;
`;

const ImageContainer = styled.img`
  height: ${({ theme }) => theme.spacing[36]};
  width: ${({ theme }) => theme.spacing[36]};
  object-fit: contain;
`;

const Title = styled.div`
  ${H5};

  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

const SubTitle = styled.div`
  ${Caption}

  color: ${({ theme }) => theme.colors.textTertiary};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

export const Header = ({
  title,
  subTitle,
  name,
  showPremium,
}: {
  title: string | ReactElement;
  subTitle: string | ReactElement;
  name: IntegrationProvider;
  showPremium: boolean;
}): ReactElement => {
  return (
    <HeaderWrapper>
      <ImageContainer src={getAssetUrl(`integrations/${name.toLowerCase()}.png?v5`)} />
      <div>
        <Title>
          <Space>
            {title}
            {showPremium && (
              <StyledTag color="pink20">
                <FormattedMessage id="integrations.premium" />
              </StyledTag>
            )}
          </Space>
        </Title>
        <SubTitle>{subTitle}</SubTitle>
      </div>
    </HeaderWrapper>
  );
};
