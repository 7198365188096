import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type Rule } from 'antd/es/form';
import { isAxiosError, type AxiosError } from 'axios';
import { FormOld, Input } from 'components/ui/atomic-components';
import { Analytics } from 'config/analytics';
import { IntegrationsApi, type NetsuiteConnectArgs } from 'data/integrations/fivetran';
import { IntegrationKeys } from 'data/integrations/fivetran/constants';
import { type Dispatch, type FC, type SetStateAction, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ConnectedStyles } from '../common/connected-form-styles';

const { ConnectedWrapper, Wrapper, NLineWrapper, NSubmitButton } = ConnectedStyles;
const CONSUMER_KEY = 'consumerKey';
const CONSUMER_SECRET = 'consumerSecret';
const ACCOUNT_ID = 'accountId';
const TOKEN_KEY = 'tokenKey';
const TOKEN_ID = 'tokenId'; //tokenSecret

export const NetsuiteForm: FC<
  React.PropsWithChildren<{
    service: number;
    setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
    setHasError: Dispatch<SetStateAction<boolean>>;
    onConnectionSuccess?: () => void;
  }>
> = ({ service, setErrorMessage, setHasError, onConnectionSuccess }) => {
  const [form] = FormOld.useForm();
  const navigate = useNavigate();

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const intl = useIntl();
  const queryClient = useQueryClient();

  const { mutate: sendNetsuiteData, isLoading } = useMutation(
    (data: NetsuiteConnectArgs) => IntegrationsApi.connectIntegration({ ...data, service }),
    {
      onSuccess: (data) => {
        onConnectionSuccess?.();
        queryClient.invalidateQueries(IntegrationKeys.ConnectedIntegrations).then(() => {
          navigate(`/data/connected/${data?.data?.id}`);
        });

        Analytics.track('New source', {
          category: 'Data',
          meta: 'Source name - Netsuit',
        });
      },
      onError: (errorResp: AxiosError) => {
        setHasError(true);
        if (isAxiosError(errorResp)) {
          const errorData = errorResp?.response?.data;

          setErrorMessage((errorData as { message: string })?.message);
        }
      },
    },
  );

  const onFinish = (): void => {
    form.validateFields().then((data) => sendNetsuiteData(data));

    setHasError(false);
    setErrorMessage(undefined);
  };

  const onFieldsChange = () => {
    const values = Object.values(form.getFieldsValue());

    setIsSubmitDisabled(values.includes('') || values.includes(undefined));
  };

  const alphaNumericRule = (field: string): Rule => {
    return {
      pattern: /^[0-9a-zA-Z]+$/,
      message: intl.formatMessage(
        {
          id: 'integrations.netsuite.form.warning_alphanumeric',
        },
        { field },
      ) as string,
    };
  };

  return (
    <Wrapper>
      <ConnectedWrapper>
        <NLineWrapper>
          <FormattedMessage id="integrations.netsuite.form.title" />
        </NLineWrapper>
        <FormOld form={form} onFieldsChange={onFieldsChange} onFinish={onFinish}>
          <FormOld.Item
            label={<FormattedMessage id="integrations.netsuite.form.label_account_id" />}
            name={ACCOUNT_ID}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'integrations.netsuite.form.warning_account_id',
                }),
              },
              {
                pattern: /^\d.*$/,
                message: intl.formatMessage({
                  id: 'integrations.netsuite.form.warning_account_id.pattern_no_match',
                }),
              },
            ]}
          >
            <Input />
          </FormOld.Item>
          <FormOld.Item
            label={<FormattedMessage id="integrations.netsuite.form.label_cons_key" />}
            name={CONSUMER_KEY}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'integrations.netsuite.form.warning_cons_key',
                }),
              },
              alphaNumericRule(
                intl.formatMessage({ id: 'integrations.netsuite.form.label_cons_key' }),
              ),
            ]}
          >
            <Input />
          </FormOld.Item>
          <FormOld.Item
            label={<FormattedMessage id="integrations.netsuite.form.label_cons_secret" />}
            name={CONSUMER_SECRET}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'integrations.netsuite.form.warning_cons_secret',
                }),
              },
              alphaNumericRule(
                intl.formatMessage({ id: 'integrations.netsuite.form.label_cons_secret' }),
              ),
            ]}
          >
            <Input />
          </FormOld.Item>
          <FormOld.Item
            label={<FormattedMessage id="integrations.netsuite.form.label_token_key" />}
            name={TOKEN_KEY}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'integrations.netsuite.form.warning_token_key',
                }),
              },
              alphaNumericRule(
                intl.formatMessage({ id: 'integrations.netsuite.form.label_token_key' }),
              ),
            ]}
          >
            <Input />
          </FormOld.Item>
          <FormOld.Item
            label={<FormattedMessage id="integrations.netsuite.form.label_token_secret" />}
            name={TOKEN_ID}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'integrations.netsuite.form.warning_token_secret',
                }),
              },
              alphaNumericRule(
                intl.formatMessage({ id: 'integrations.netsuite.form.label_token_secret' }),
              ),
            ]}
          >
            <Input />
          </FormOld.Item>
          <FormOld.Item>
            <NSubmitButton
              block
              disabled={isSubmitDisabled}
              htmlType="submit"
              loading={isLoading}
              type="primary"
            >
              <FormattedMessage id="submit" />
            </NSubmitButton>
          </FormOld.Item>
        </FormOld>
      </ConnectedWrapper>
    </Wrapper>
  );
};
