import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError, type AxiosError } from 'axios';
import { FormOld, Input, notification } from 'components/ui/atomic-components';
import { Analytics } from 'config/analytics';
import { IntegrationsApi, type LookerConnectArgs } from 'data/integrations/fivetran';
import { IntegrationKeys } from 'data/integrations/fivetran/constants';
import { type FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ConnectedStyles } from '../common/connected-form-styles';

const { ConnectedWrapper, Wrapper, NLineWrapper, NSubmitButton, WarningAlert } = ConnectedStyles;
const CONSUMER_KEY = 'lookerClientId';
const CONSUMER_SECRET = 'lookerClientSecret';
const SUBDOMAIN = 'subdomain';

export const LookerForm: FC<
  React.PropsWithChildren<{ service: number; onConnectionSuccess?: () => void }>
> = ({ service, onConnectionSuccess }) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [form] = FormOld.useForm();
  const navigate = useNavigate();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const intl = useIntl();
  const queryClient = useQueryClient();

  const { mutate: sendLookerData, isLoading } = useMutation(
    (data: LookerConnectArgs) => IntegrationsApi.connectIntegration({ ...data, service }),
    {
      onSuccess: (data) => {
        notification.success({
          message: intl.formatMessage({ id: 'integrations.looker.notification.success.title' }),
          description: intl.formatMessage({
            id: 'integrations.looker.notification.success.description',
          }),
        });
        onConnectionSuccess?.();
        queryClient.invalidateQueries(IntegrationKeys.ConnectedIntegrations).then(() => {
          navigate(`/data/connected/${data?.data?.id}?pending=add-data`);
        });
        setHasError(false);
        setErrorMessage(undefined);

        Analytics.track('New source', {
          category: 'Data',
          meta: 'Source name - Looker',
        });
      },
      onError: (errorResp: AxiosError) => {
        setHasError(true);
        if (isAxiosError(errorResp)) {
          const errorData = errorResp?.response?.data;

          setErrorMessage((errorData as { message: string })?.message);
        }
      },
    },
  );

  const onFinish = (): void => {
    setHasError(false);
    setErrorMessage(undefined);
    form.validateFields().then((data) => sendLookerData(data));
  };

  const onFieldsChange = () => {
    const values = Object.values(form.getFieldsValue());

    setIsSubmitDisabled(values.includes('') || values.includes(undefined));
  };

  return (
    <Wrapper>
      <ConnectedWrapper>
        {hasError && (
          <WarningAlert
            closable
            message={
              errorMessage || <FormattedMessage id="integrations.add_data_modal.common_error" />
            }
            type="warning"
          />
        )}
        <NLineWrapper>
          <FormattedMessage id="integrations.looker.form.title" />
        </NLineWrapper>
        <FormOld form={form} onFieldsChange={onFieldsChange} onFinish={onFinish}>
          <FormOld.Item
            label={<FormattedMessage id="integrations.looker.form.label_subdomain" />}
            name={SUBDOMAIN}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'integrations.looker.form.warning_subdomain',
                }),
              },
            ]}
          >
            <Input />
          </FormOld.Item>
          <FormOld.Item
            label={<FormattedMessage id="integrations.looker.form.label_cons_key" />}
            name={CONSUMER_KEY}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'integrations.looker.form.warning_cons_key',
                }),
              },
            ]}
          >
            <Input />
          </FormOld.Item>
          <FormOld.Item
            label={<FormattedMessage id="integrations.looker.form.label_cons_secret" />}
            name={CONSUMER_SECRET}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'integrations.looker.form.warning_cons_secret',
                }),
              },
            ]}
          >
            <Input />
          </FormOld.Item>
          <FormOld.Item>
            <NSubmitButton
              block
              disabled={isSubmitDisabled}
              htmlType="submit"
              loading={isLoading}
              type="primary"
            >
              <FormattedMessage id="submit" />
            </NSubmitButton>
          </FormOld.Item>
        </FormOld>
      </ConnectedWrapper>
    </Wrapper>
  );
};
