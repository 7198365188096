import { ReactComponent as CurrencyDollarIcon } from 'assets/v2/currency-dollar.svg';
import { ReactComponent as LinkIcon } from 'assets/v2/link-data.svg';
import { Chips, IconShell } from 'components/ui/atomic-components';
import { type IntegrationConnectionItem } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { getAssetUrl } from 'utils';
import { GridStyles } from './styles';

const { Box, ImageContainer, Name, PremiumIconWrapper, LeftSection } = GridStyles;

export const IntegrationGridItem = ({
  item,
  count,
  onActivateItem,
}: {
  item: IntegrationConnectionItem;
  count?: number;
  onActivateItem(item: IntegrationConnectionItem): void;
}): ReactElement => {
  return (
    <Box key={item.id} onClick={() => onActivateItem(item)}>
      <LeftSection>
        <ImageContainer
          alt=""
          src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)}
        />
        <Name>{item.displayName}</Name>
      </LeftSection>
      <div>
        {item.isPremium && (
          <PremiumIconWrapper>
            <IconShell color="green600" icon={CurrencyDollarIcon} size="xs" />
          </PremiumIconWrapper>
        )}
        {!item.isPremium && !!count && (
          <Chips
            bgColor="gray100"
            borderColor="outlineDefault"
            icon={<IconShell icon={LinkIcon} size="xs" />}
            size="small"
            textColor="textTitle2"
          >
            {count}
          </Chips>
        )}
      </div>
    </Box>
  );
};
