import Bugsnag from '@bugsnag/js';
import { ActionInProgressOverlay, Button } from 'components/ui/atomic-components';
import { ModelVersionApi } from 'data/modelling/model/versions/api';
import { type ModelTemplate } from 'data/templates';
import { useTemplateMutations } from 'data/templates/hooks/use-template-mutations';
import Cookies from 'js-cookie';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  PREVIEW_MODEL_TEMPLATE,
  VERSION_ACCESS_COOKIE_KEY,
  VERSION_MODE_COOKIE_KEY,
} from './constants';

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[12]};
  align-items: center;
`;

const UseTemplateButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.orange600};
  border: none;
  color: ${({ theme }) => theme.colors.gray00};

  &:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: ${({ theme }) => theme.colors.orange500};
    color: ${({ theme }) => theme.colors.gray00};
  }
`;

interface Props {
  label: string;
  previewModelTemplate?: ModelTemplate;
}

export const BannerTitle = ({ label, previewModelTemplate }: Props): ReactElement => {
  const { applyTemplateMutation } = useTemplateMutations();

  const [isLoading, setIsLoading] = useState(false);

  const handleUseTemplate = async () => {
    if (!previewModelTemplate) {
      return;
    }

    setIsLoading(true);
    try {
      await ModelVersionApi.exitVersionMode({});
    } catch {
      Bugsnag.notify('Apply preview template failed');
      setIsLoading(false);
    }

    Cookies.remove(VERSION_MODE_COOKIE_KEY);
    Cookies.remove(VERSION_ACCESS_COOKIE_KEY);
    Cookies.remove(PREVIEW_MODEL_TEMPLATE);

    await applyTemplateMutation.mutateAsync(previewModelTemplate);
  };

  return (
    <>
      <Wrapper>
        {label}

        {previewModelTemplate && (
          <UseTemplateButton loading={isLoading} size="extraSmall" onClick={handleUseTemplate}>
            <FormattedMessage id="model.templates.content.header.use_template" />
          </UseTemplateButton>
        )}
      </Wrapper>
      <ActionInProgressOverlay
        open={applyTemplateMutation.isLoading}
        text={
          <FormattedMessage id="model.templates.content.use_template.modal.applying_template" />
        }
      />
    </>
  );
};
