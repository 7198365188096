import { IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { type GroupBase, type SingleValueProps, components } from 'react-select';
import styled from 'styled-components';
import { FontS } from 'styles/typography';
import { type CurrencySelectOption } from '../types';

const SingleValueWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing[8]};
`;

const Label = styled.div`
  ${FontS}

  color: ${({ theme }) => theme.colors.gray800};
`;

export const SingleValue = ({
  data,
  getValue,
  ...rest
}: SingleValueProps<
  CurrencySelectOption,
  false,
  GroupBase<CurrencySelectOption>
>): ReactElement => {
  return (
    <components.SingleValue data={data} getValue={getValue} {...rest}>
      <SingleValueWrapper>
        <IconShell color="iconDefault" icon={data.icon} size="xs" />
        <Label>{data.label}</Label>
      </SingleValueWrapper>
    </components.SingleValue>
  );
};
