import { Button, Typography } from 'components/ui/atomic-components';
import styled, { css } from 'styled-components';
import { FontL, FontS, FontXl } from 'styles/typography';

const Common = css`
  ${({ theme }) => {
    return `font-size: ${theme.fontSize.paragraph}; color: ${theme.colors.textSecondary}`;
  }}
`;

export const PopupModalStyles = {
  FullWidthWrapper: styled.div`
    display: flex;
  `,
  Wrapper: styled.div`
    display: flex;
    height: 100%;

    > * {
      border-right: 1px solid ${({ theme }) => theme.colors.black40};

      :last-child {
        border-right: none;
      }
    }
  `,

  Content: styled.div<{ $isFullWidth?: boolean }>`
    width: 500px;
    padding: 16px 80px;
    overflow: auto;
    height: 100%;
    gap: ${({ theme }) => theme.spacing[8]};
  `,

  StyledP: styled(Typography.Paragraph)`
    ${Common};
  `,

  StyledOL: styled.ol`
    ${Common};

    margin-bottom: ${({ theme }) => theme.spacing[32]};
  `,

  StyledLI: styled.li`
    ${Common};

    padding-top: ${({ theme }) => theme.spacing[4]};
  `,

  StyledButton: styled(Button)<{ $width?: string }>`
    width: ${({ $width }) => $width ?? '170px'};

    > span {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: ${({ theme }) => theme.spacing[2]};
    }
  `,
};

export const FormConnectorStyles = {
  LeftPanel: styled.div`
    width: 300px;
    padding: ${({ theme }) => theme.spacing[24]};
    display: flex;
    gap: ${({ theme }) => theme.spacing[8]};
    flex-direction: column;
    height: 100%;
    border-right: 1px solid ${({ theme }) => theme.colors.outlineDefault};
  `,
  RightPanel: styled.div`
    width: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    padding: 16px 80px;
    height: 100%;
    gap: ${({ theme }) => theme.spacing[8]};
  `,
  FullWidthWrapper: styled.div`
    display: flex;
    height: 100%;
    width: 100%;
  `,
  Title: styled.div`
    ${FontXl};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin-bottom: ${({ theme }) => theme.spacing[8]};
  `,
  Description: styled.div`
    ${FontS};

    margin-bottom: ${({ theme }) => theme.spacing[12]};
  `,
  ImageContainer: styled.img`
    height: 32px;
    width: 32px;
    object-fit: contain;
    margin-bottom: ${({ theme }) => theme.spacing[16]};
  `,
  RightTitle: styled.div`
    ${FontL};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.textTitle2};
  `,
  RightDescription: styled.div`
    ${FontS};

    margin-bottom: ${({ theme }) => theme.spacing[12]};
  `,
};
