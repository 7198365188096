import { ReactComponent as TickIcon } from 'assets/ic_connected.svg';
import { Button } from 'components/ui/atomic-components';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { FinishModalStyles } from './styles';

const { StyledModal, StyledShell, Subtext, HeavyText, ContentWrapper } = FinishModalStyles;

export const FinishModal: FC<
  React.PropsWithChildren<{ visible: boolean; onClose: () => void }>
> = ({ visible, onClose }) => {
  return (
    <StyledModal closable={false} destroyOnClose open={visible} width={320}>
      <ContentWrapper>
        <StyledShell icon={TickIcon} />
        <HeavyText>
          <FormattedMessage id="integrations.premium.finish.title" />
        </HeavyText>
        <Subtext>
          <FormattedMessage id="integrations.premium.finish.subtitle" />
        </Subtext>
        <Button onClick={() => onClose()}>
          <FormattedMessage id="okay" />
        </Button>
      </ContentWrapper>
    </StyledModal>
  );
};
