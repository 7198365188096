export enum Currency {
  USD = 'USD',
  INR = 'INR',
  EUR = 'EUR',
  AED = 'AED',
  GBP = 'GBP',
  SGD = 'SGD',
  AUD = 'AUD',
  CAD = 'CAD',
  ZAR = 'ZAR',
  ETH = 'ETH',
  ILS = 'ILS',
  JPY = 'JPY',
  CNY = 'CNY',
  THB = 'THB',
  DAI = 'DAI',
}

export const currencyLocaleMap: Record<Currency, string> = {
  [Currency.USD]: 'en-US',
  [Currency.INR]: 'en-IN',
  [Currency.EUR]: 'en-EU',
  [Currency.AED]: 'ar-AE',
  [Currency.AUD]: 'en-US',
  [Currency.CAD]: 'en-US',
  [Currency.GBP]: 'en-GB',
  [Currency.SGD]: 'en-SG',
  [Currency.ZAR]: 'en-ZA',
  [Currency.ILS]: 'en-US',
  [Currency.JPY]: 'ja-JP',
  [Currency.CNY]: 'zh-CN',
  [Currency.THB]: 'th-TH',
  [Currency.ETH]: 'en-US',
  [Currency.DAI]: 'en-US',
};

export const CurrencySymbolMapping: Record<Currency, string> = {
  [Currency.USD]: '$',
  [Currency.INR]: '₹',
  [Currency.EUR]: '€',
  [Currency.AED]: 'د.إ.',
  [Currency.AUD]: 'A$',
  [Currency.CAD]: 'C$',
  [Currency.GBP]: '£',
  [Currency.SGD]: 'S$',
  [Currency.ZAR]: 'R ',
  [Currency.ILS]: '₪',
  [Currency.JPY]: '¥',
  [Currency.CNY]: '￥',
  [Currency.THB]: '฿',
  [Currency.ETH]: 'ETH ',
  [Currency.DAI]: 'xDAI ',
};

export const CurrencyRegexMap: Record<Currency, RegExp> = {
  [Currency.USD]: /^[+-]?\$(\.\d+|\d+\.?\d*)$/,
  [Currency.INR]: /^[+-]?₹(\.\d+|\d+\.?\d*)$/,
  [Currency.EUR]: /^[+-]?€(\.\d+|\d+\.?\d*)$/,
  [Currency.AED]: /^[+-]?د.إ.(\.\d+|\d+\.?\d*)$/,
  [Currency.AUD]: /^[+-]?A\$(\.\d+|\d+\.?\d*)$/,
  [Currency.CAD]: /^[+-]?CA\$(\.\d+|\d+\.?\d*)$/,
  [Currency.GBP]: /^[+-]?£(\.\d+|\d+\.?\d*)$/,
  [Currency.SGD]: /^[+-]?S\$(\.\d+|\d+\.?\d*)$/,
  [Currency.ZAR]: /^[+-]?R (\.\d+|\d+\.?\d*)$/,
  [Currency.ILS]: /^[+-]?₪(\.\d+|\d+\.?\d*)$/,
  [Currency.JPY]: /^[+-]?¥(\.\d+|\d+\.?\d*)$/,
  [Currency.CNY]: /^[+-]?￥(\.\d+|\d+\.?\d*)$/,
  [Currency.THB]: /^[+-]?฿(\.\d+|\d+\.?\d*)$/,
  [Currency.ETH]: /^[+-]?ETH (\.\d+|\d+\.?\d*)$/,
  [Currency.DAI]: /^[+-]?xDAI (\.\d+|\d+\.?\d*)$/,
};
