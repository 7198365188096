import { type Currency } from 'data/currencies';
import { type ReactElement } from 'react';
import { Select } from '../atomic-components';
import { useCurrencyOptions } from './hooks';
import { Option } from './select-components/option';
import { SingleValue } from './select-components/single-value';

interface Props {
  value?: Currency;
  isDisabled?: boolean;
  enableAllCurrenciesOptions?: boolean;
  onChange?: (currency: Currency) => void;
}

export const CurrencySelector = ({
  value,
  isDisabled,
  enableAllCurrenciesOptions,
  onChange,
}: Props): ReactElement => {
  const currencyOptions = useCurrencyOptions({ enableAllCurrenciesOptions });

  return (
    <Select
      components={{ Option, SingleValue }}
      isDisabled={isDisabled}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      options={currencyOptions}
      size="small"
      styles={{ menu: (base) => ({ ...base, minWidth: 150 }) }}
      value={currencyOptions.find((option) => option.value === value)}
      onChange={(currency) => onChange?.(currency?.value)}
    />
  );
};
