import { useQueryClient } from '@tanstack/react-query';
import { useListMutations } from 'components/modules/modelling/lists/hooks';
import {
  type PivotKeyFormProps,
  PivotKeyModal,
} from 'components/modules/modelling/lists/pivot-key/modal';
import { type List } from 'data/modelling/lists';
import { isEmpty } from 'lodash';
import { type ReactElement, type Dispatch, type SetStateAction, useMemo } from 'react';
import { useListStoreContext } from 'store/lists';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const UniqueKeyModal = ({ open, setOpen }: Props): ReactElement => {
  const queryClient = useQueryClient();

  const config = useListStoreContext((s) => s.config);
  const listId = useListStoreContext((s) => s.id);

  const { updateListMutation } = useListMutations();

  const onSave = async (data: PivotKeyFormProps) => {
    const updatedList = {
      config: {
        ...config,
        uniqueKeyColumns: data.uniqueKeyColumns,
      },
    } as List;

    await updateListMutation.mutateAsync({ id: listId, list: updatedList });

    queryClient.invalidateQueries(['lists', listId]);

    setOpen(false);
  };

  const listColumns = useMemo(() => {
    const isCompositeUniqueKey = !isEmpty(config.uniqueKeyColumns);

    const formulaColumns = new Set(config.columnFormulae?.map((column) => column.colName));

    return config.columnOrder.filter((col) => {
      if (isCompositeUniqueKey) {
        return col !== config.uniqueKey;
      }

      return !formulaColumns.has(col);
    });
  }, [config.columnFormulae, config.columnOrder, config.uniqueKey, config.uniqueKeyColumns]);

  return (
    <PivotKeyModal
      defaultUniqueKey={config.uniqueKey || ''}
      defaultUniqueKeyColumns={config.uniqueKeyColumns || []}
      listColumns={listColumns}
      open={open}
      setOpen={setOpen}
      onSave={onSave}
    />
  );
};
